import axiosIns from '@/libs/axios'

export function getWarehouse({ id, includes }) {
  return axiosIns.get(`/shop/warehouses/${id}`, {
    params: {
      includes,
    },
  })
}

export function getWarehouses({
  project, perPage, sortedBy, orderBy, page, search, searchFields, searchJoin, includes,
}) {
  return axiosIns.get('/shop/warehouses', {
    params: {
      project,
      perPage,
      sortedBy,
      orderBy,
      page,
      search,
      searchFields,
      searchJoin,
      includes,
    },
  })
}

export function createWarehouse({
  project_id,
  name,
  contact_information,
  working_schedule,
}) {
  return axiosIns.post('/shop/warehouses', {
    project_id,
    name,
    contact_information,
    working_schedule,
  })
}

export function updateWarehouse({
  id,
  name,
  contact_information,
  working_schedule,
}) {
  return axiosIns.patch(`/shop/warehouses/${id}`, {
    name,
    contact_information,
    working_schedule,
  })
}

export function deleteWarehouse(id) {
  return axiosIns.delete(`/shop/warehouses/${id}`)
}

export function attachYandexMarketFbs({
  id,
  ymfbsID,
  sort_order,
}) {
  return axiosIns.post(`/shop/warehouses/${id}/yandex-market-fbs/${ymfbsID}`, {
    sort_order,
  })
}

export function updateAttachingYandexMarketFbs({
  id,
  ymfbsID,
  sort_order,
}) {
  return axiosIns.patch(`/shop/warehouses/${id}/yandex-market-fbs/${ymfbsID}`, {
    sort_order,
  })
}

export function detachYandexMarketFbs({
  id,
  ymfbsID,
}) {
  return axiosIns.delete(`/shop/warehouses/${id}/yandex-market-fbs/${ymfbsID}`)
}

export function attachYandexMarketDbs({
  id,
  ymdbsID,
  sort_order,
}) {
  return axiosIns.post(`/shop/warehouses/${id}/yandex-market-dbs/${ymdbsID}`, {
    sort_order,
  })
}

export function updateAttachingYandexMarketDbs({
  id,
  ymdbsID,
  sort_order,
}) {
  return axiosIns.patch(`/shop/warehouses/${id}/yandex-market-dbs/${ymdbsID}`, {
    sort_order,
  })
}

export function detachYandexMarketDbs({
  id,
  ymdbsID,
}) {
  return axiosIns.delete(`/shop/warehouses/${id}/yandex-market-dbs/${ymdbsID}`)
}

export default {
  getWarehouse,
  getWarehouses,
  createWarehouse,
  updateWarehouse,
  deleteWarehouse,
}
