import {
  ref,
} from 'vue'
import get from 'lodash/get'
import store from '@/store'
import { getWarehouses } from '@/services/main-api/shop/warehouses'

const items = ref([])

const getItems = async ({
  includes = 'real_address',
} = ({
  includes: 'real_address',
})) => {
  try {
    const res = await getWarehouses({
      project: store.getters['workingMode/selected_project_id'],
      perPage: 1000,
      sortedBy: 'desc',
      orderBy: 'name',
      page: 1,
      includes,
    })

    items.value = res.data.data
  } catch (e) {
    items.value = []
  }
}

const getWarehouseNameByID = id => {
  const item = items.value.find(i => i.id === id)
  return get(item, 'name', '')
}

export default function useWarehouseList() {
  return {
    getItems,
    getWarehouseNameByID,
    items,
  }
}
