<template>
  <svg
    v-if="type === 'full'"
    class="app-logo"
    width="100"
    height="28"
    viewBox="0 0 100 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M49.4536 2.29612C48.848 0.797614 47.113 0.824615 46.5848 2.13862C37.022 25.9121 38.1604 22.9511 36.9992 25.9526C36.9172 26.1686 36.7578 27.0686 37.4546 27.2666C37.6865 27.3177 37.9294 27.2821 38.1363 27.1665C38.3432 27.051 38.4994 26.8638 38.5748 26.6411C42.0083 18.1226 41.1613 20.2601 41.9445 18.2756L54.0574 18.1676L57.3133 26.4521C57.5729 27.1001 57.8917 27.6131 58.5838 27.3836C59.276 27.1541 59.2441 26.5601 59.0073 25.9526C57.8325 22.9286 58.9891 25.8941 49.4536 2.29612ZM42.4773 16.6241L47.9964 2.84512L53.4609 16.5926L42.4773 16.6241Z"
      fill="black"
    />
    <path
      d="M75.4733 16.8037C74.5876 15.7495 73.4774 14.9013 72.2219 14.3197C71.6117 14.0182 70.5826 13.5457 69.9815 13.3117C68.6527 12.7998 67.3609 12.1984 66.1154 11.5117L65.6145 11.1967C65.4415 11.0707 65.3094 10.9762 65.1591 10.8457L64.3212 10.0492C63.7688 9.42732 63.4126 8.65924 63.2967 7.8397C63.2086 7.15347 63.3224 6.45668 63.6245 5.83271C64.5854 3.85271 66.6846 2.89421 68.8476 2.89421C70.7602 2.89421 72.2037 3.48821 73.7338 4.24421L74.4259 4.62221C74.9314 4.89671 75.068 4.76171 75.4414 4.22171C75.6691 3.89771 75.792 3.52871 75.5325 3.36221C74.7526 2.90086 73.9382 2.49919 73.0963 2.16071C71.2234 1.33765 69.1434 1.08993 67.1263 1.44971C66.0951 1.6298 65.1183 2.03727 64.2684 2.64184C63.4185 3.2464 62.7175 4.03251 62.2174 4.94171C61.8536 5.6183 61.6497 6.36755 61.6209 7.1332C61.5542 8.23214 61.8411 9.32375 62.4406 10.2517C63.7247 12.3712 66.211 13.4782 68.4924 14.4457L69.6719 14.9497C69.8813 15.0352 70.0862 15.1162 70.2729 15.2062C71.3795 15.7372 72.6318 16.2232 73.5061 17.0962L73.9341 17.5192C74.8702 18.4942 75.41 19.7758 75.4505 21.1192C75.4505 23.3917 73.793 24.8992 71.7347 25.4797C69.4578 26.1187 66.3431 25.7542 64.3076 24.9442L61.9305 23.7787C61.5435 23.5942 61.375 23.7022 61.2475 23.8957C61.12 24.0892 60.9788 24.3457 60.8695 24.5212C60.7602 24.6967 60.6874 24.9712 61.1109 25.1962L62.477 25.8937C64.5071 26.918 66.7597 27.437 69.0389 27.4057C70.0206 27.4101 70.9992 27.2968 71.9533 27.0682C72.7596 26.87 73.5284 26.5449 74.2301 26.1052C75.3435 25.4318 76.2029 24.4163 76.6766 23.2143C77.1503 22.0122 77.2123 20.6899 76.8531 19.4497C76.5867 18.4816 76.1165 17.5799 75.4733 16.8037Z"
      fill="black"
    />
    <path
      d="M98.7792 1.23438H97.9732C97.8766 1.23438 97.7839 1.27231 97.7156 1.33982C97.6473 1.40733 97.6089 1.4989 97.6089 1.59438V27.1004C97.6089 27.1958 97.6473 27.2874 97.7156 27.3549C97.7839 27.4224 97.8766 27.4604 97.9732 27.4604H98.7792C98.8616 27.4604 98.9416 27.4329 99.0061 27.3822C99.0706 27.3315 99.1158 27.2607 99.1344 27.1814V1.51338C99.1158 1.43403 99.0706 1.36323 99.0061 1.31256C98.9416 1.26189 98.8616 1.23432 98.7792 1.23438V1.23438Z"
      fill="black"
    />
    <path
      d="M93.0644 16.8044C92.1787 15.7502 91.0686 14.902 89.8131 14.3204C89.2029 14.0189 88.1692 13.5464 87.5727 13.3124C86.2438 12.8005 84.9521 12.1991 83.7066 11.5124L83.2056 11.1974C83.0326 11.0714 82.9005 10.9769 82.7503 10.8464L81.9078 10.0499C81.3567 9.43017 80.9992 8.6658 80.8787 7.84939C80.7917 7.16363 80.9039 6.46757 81.202 5.84239C82.1674 3.86239 84.2667 2.90389 86.4297 2.90389C88.3422 2.90389 89.7858 3.49789 91.3158 4.25389L91.9852 4.60039C92.4907 4.87489 92.6273 4.73989 93.0007 4.19989C93.2284 3.87589 93.3513 3.50689 93.0918 3.34039C92.3108 2.87808 91.4948 2.47638 90.651 2.13889C88.7813 1.32652 86.7082 1.0867 84.6993 1.45039C83.6675 1.63047 82.69 2.0379 81.8394 2.64243C80.9888 3.24697 80.2869 4.03307 79.7858 4.94239C79.4256 5.62015 79.2234 6.36891 79.1938 7.13389C79.1271 8.23282 79.414 9.32443 80.0135 10.2524C81.2976 12.3719 83.784 13.4789 86.0654 14.4464L87.2402 14.9459C87.4452 15.0314 87.6501 15.1124 87.8413 15.2024C88.9479 15.7334 90.2002 16.2194 91.0745 17.0924L91.5025 17.5154C92.4385 18.4904 92.9784 19.772 93.0189 21.1154C93.0189 23.3879 91.3614 24.8954 89.2985 25.4759C87.0217 26.1149 83.9115 25.7504 81.876 24.9404L79.5126 23.7929C79.1255 23.6084 78.957 23.7164 78.8295 23.9099C78.702 24.1034 78.5608 24.3599 78.4516 24.5354C78.3423 24.7109 78.2694 24.9854 78.6929 25.2104L80.059 25.9079C82.091 26.9317 84.3448 27.4507 86.6255 27.4199C87.6072 27.4243 88.5858 27.311 89.5399 27.0824C90.3459 26.8833 91.1145 26.5582 91.8167 26.1194C92.9337 25.446 93.7959 24.4285 94.2706 23.2236C94.7454 22.0186 94.8064 20.6929 94.4442 19.4504C94.1778 18.4823 93.7077 17.5806 93.0644 16.8044Z"
      fill="black"
    />
    <path
      d="M27.3186 13.4735C27.2576 10.8934 26.4494 8.38462 24.9899 6.24444C23.5305 4.10426 21.4808 2.42234 19.0837 1.39794C16.6866 0.373534 14.0427 0.0495854 11.465 0.464468C8.88739 0.87935 6.4841 2.01568 4.53989 3.73882C2.59568 5.46197 1.19202 7.69972 0.495224 10.187C-0.201576 12.6742 -0.162322 15.3067 0.608336 17.7726C1.37899 20.2385 2.84876 22.4344 4.8435 24.1002C6.83824 25.766 9.27437 26.8318 11.8633 27.1715C12.109 27.2119 12.3568 27.239 12.6055 27.2525H27.3186V13.4735ZM25.5517 25.511H13.6347C11.2777 25.511 8.97364 24.8203 7.01388 23.5263C5.05413 22.2323 3.52669 20.393 2.62471 18.2411C1.72274 16.0893 1.48674 13.7214 1.94656 11.437C2.40638 9.15257 3.54138 7.05419 5.20801 5.40722C6.87464 3.76025 8.99806 2.63865 11.3098 2.18425C13.6214 1.72985 16.0176 1.96306 18.1951 2.8544C20.3727 3.74573 22.2339 5.25516 23.5434 7.19179C24.8528 9.12843 25.5517 11.4053 25.5517 13.7345V25.511Z"
      fill="black"
    />
  </svg>
  <svg
    v-else-if="type === 'pic'"
    class="app-logo"
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.3186 13.4735C27.2576 10.8934 26.4494 8.38462 24.9899 6.24444C23.5305 4.10426 21.4808 2.42234 19.0837 1.39794C16.6866 0.373534 14.0427 0.0495854 11.465 0.464468C8.88739 0.87935 6.4841 2.01568 4.53989 3.73882C2.59568 5.46197 1.19202 7.69972 0.495224 10.187C-0.201576 12.6742 -0.162322 15.3067 0.608336 17.7726C1.37899 20.2385 2.84876 22.4344 4.8435 24.1002C6.83824 25.766 9.27437 26.8318 11.8633 27.1715C12.109 27.2119 12.3568 27.239 12.6055 27.2525H27.3186V13.4735ZM25.5517 25.511H13.6347C11.2777 25.511 8.97364 24.8203 7.01388 23.5263C5.05413 22.2323 3.52669 20.393 2.62471 18.2411C1.72274 16.0893 1.48674 13.7214 1.94656 11.437C2.40638 9.15257 3.54138 7.05419 5.20801 5.40722C6.87464 3.76025 8.99806 2.63865 11.3098 2.18425C13.6214 1.72985 16.0176 1.96306 18.1951 2.8544C20.3727 3.74573 22.2339 5.25516 23.5434 7.19179C24.8528 9.12843 25.5517 11.4053 25.5517 13.7345V25.511Z"
      fill="black"
    />
  </svg>
  <svg
    v-else-if="type === 'brand'"
    class="app-logo"
    width="64"
    height="27"
    viewBox="0 0 64 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.4536 1.29612C12.848 -0.202386 11.113 -0.175385 10.5848 1.13862C1.02196 24.9121 2.16039 21.9511 0.99919 24.9526C0.917223 25.1686 0.757843 26.0686 1.45456 26.2666C1.6865 26.3177 1.92937 26.2821 2.13626 26.1665C2.34315 26.051 2.49938 25.8638 2.57478 25.6411C6.00828 17.1226 5.16129 19.2601 5.94453 17.2756L18.0574 17.1676L21.3133 25.4521C21.5729 26.1001 21.8917 26.6131 22.5838 26.3836C23.276 26.1541 23.2441 25.5601 23.0073 24.9526C21.8325 21.9286 22.9891 24.8941 13.4536 1.29612ZM6.47731 15.6241L11.9964 1.84512L17.4609 15.5926L6.47731 15.6241Z"
      fill="black"
    />
    <path
      d="M39.4733 15.8037C38.5876 14.7495 37.4774 13.9013 36.2219 13.3197C35.6117 13.0182 34.5826 12.5457 33.9815 12.3117C32.6527 11.7998 31.3609 11.1984 30.1154 10.5117L29.6145 10.1967C29.4415 10.0707 29.3094 9.9762 29.1591 9.8457L28.3212 9.0492C27.7688 8.42732 27.4126 7.65924 27.2967 6.8397C27.2086 6.15347 27.3224 5.45668 27.6245 4.83271C28.5854 2.85271 30.6846 1.89421 32.8476 1.89421C34.7602 1.89421 36.2037 2.48821 37.7338 3.24421L38.4259 3.62221C38.9314 3.89671 39.068 3.76171 39.4414 3.22171C39.6691 2.89771 39.792 2.52871 39.5325 2.36221C38.7526 1.90086 37.9382 1.49919 37.0963 1.16071C35.2234 0.337653 33.1434 0.0899344 31.1263 0.449708C30.0951 0.629797 29.1183 1.03727 28.2684 1.64184C27.4185 2.2464 26.7175 3.03251 26.2174 3.94171C25.8536 4.6183 25.6497 5.36755 25.6209 6.1332C25.5542 7.23214 25.8411 8.32375 26.4406 9.2517C27.7247 11.3712 30.211 12.4782 32.4924 13.4457L33.6719 13.9497C33.8813 14.0352 34.0862 14.1162 34.2729 14.2062C35.3795 14.7372 36.6318 15.2232 37.5061 16.0962L37.9341 16.5192C38.8702 17.4942 39.41 18.7758 39.4505 20.1192C39.4505 22.3917 37.793 23.8992 35.7347 24.4797C33.4578 25.1187 30.3431 24.7542 28.3076 23.9442L25.9305 22.7787C25.5435 22.5942 25.375 22.7022 25.2475 22.8957C25.12 23.0892 24.9788 23.3457 24.8695 23.5212C24.7602 23.6967 24.6874 23.9712 25.1109 24.1962L26.477 24.8937C28.5071 25.918 30.7597 26.437 33.0389 26.4057C34.0206 26.4101 34.9992 26.2968 35.9533 26.0682C36.7596 25.87 37.5284 25.5449 38.2301 25.1052C39.3435 24.4318 40.2029 23.4163 40.6766 22.2143C41.1503 21.0122 41.2123 19.6899 40.8531 18.4497C40.5867 17.4816 40.1165 16.5799 39.4733 15.8037Z"
      fill="black"
    />
    <path
      d="M62.7792 0.234375H61.9732C61.8766 0.234375 61.7839 0.272307 61.7156 0.33982C61.6473 0.407333 61.6089 0.498898 61.6089 0.594376V26.1004C61.6089 26.1958 61.6473 26.2874 61.7156 26.3549C61.7839 26.4224 61.8766 26.4604 61.9732 26.4604H62.7792C62.8616 26.4604 62.9416 26.4329 63.0061 26.3822C63.0706 26.3315 63.1158 26.2607 63.1344 26.1814V0.513376C63.1158 0.434027 63.0706 0.363234 63.0061 0.31256C62.9416 0.261886 62.8616 0.234323 62.7792 0.234375V0.234375Z"
      fill="black"
    />
    <path
      d="M57.0644 15.8044C56.1787 14.7502 55.0686 13.902 53.8131 13.3204C53.2029 13.0189 52.1692 12.5464 51.5727 12.3124C50.2438 11.8005 48.9521 11.1991 47.7066 10.5124L47.2056 10.1974C47.0326 10.0714 46.9005 9.97689 46.7503 9.84639L45.9078 9.04989C45.3567 8.43017 44.9992 7.6658 44.8787 6.84939C44.7917 6.16363 44.9039 5.46757 45.202 4.84239C46.1674 2.86239 48.2667 1.90389 50.4297 1.90389C52.3422 1.90389 53.7858 2.49789 55.3158 3.25389L55.9852 3.60039C56.4907 3.87489 56.6273 3.73989 57.0007 3.19989C57.2284 2.87589 57.3513 2.50689 57.0918 2.34039C56.3108 1.87808 55.4948 1.47638 54.651 1.13889C52.7813 0.326522 50.7082 0.0866992 48.6993 0.450389C47.6675 0.63047 46.69 1.0379 45.8394 1.64243C44.9888 2.24697 44.2869 3.03307 43.7858 3.94239C43.4256 4.62015 43.2234 5.36891 43.1938 6.13389C43.1271 7.23282 43.414 8.32443 44.0135 9.25239C45.2976 11.3719 47.784 12.4789 50.0654 13.4464L51.2402 13.9459C51.4452 14.0314 51.6501 14.1124 51.8413 14.2024C52.9479 14.7334 54.2002 15.2194 55.0745 16.0924L55.5025 16.5154C56.4385 17.4904 56.9784 18.772 57.0189 20.1154C57.0189 22.3879 55.3614 23.8954 53.2985 24.4759C51.0217 25.1149 47.9115 24.7504 45.876 23.9404L43.5126 22.7929C43.1255 22.6084 42.957 22.7164 42.8295 22.9099C42.702 23.1034 42.5608 23.3599 42.4516 23.5354C42.3423 23.7109 42.2694 23.9854 42.6929 24.2104L44.059 24.9079C46.091 25.9317 48.3448 26.4507 50.6255 26.4199C51.6072 26.4243 52.5858 26.311 53.5399 26.0824C54.3459 25.8833 55.1145 25.5582 55.8167 25.1194C56.9337 24.446 57.7959 23.4285 58.2706 22.2236C58.7454 21.0186 58.8064 19.6929 58.4442 18.4504C58.1778 17.4823 57.7077 16.5806 57.0644 15.8044Z"
      fill="black"
    />
  </svg>
</template>

<script>
export default {
  name: 'LogoApp',
  props: {
    type: {
      type: String,
      default: 'full',
    },
  },
}
</script>

<style lang="scss">
.dark-layout .app-logo{

    path{
      fill: white;
    }

}
</style>
