import axiosIns from '@/libs/axios'

export function getProductItem({ id, includes }) {
  return axiosIns.get(`/shop/orders/product-items/${id}`, {
    params: {
      includes,
    },
  })
}

export function getProductItems({
  order, perPage, sortedBy, orderBy, page, search, searchFields, searchJoin, includes,
}) {
  return axiosIns.get('/shop/orders/product-items', {
    params: {
      order,
      perPage,
      sortedBy,
      orderBy,
      page,
      search,
      searchFields,
      searchJoin,
      includes,
    },
  })
}

export function createProductItem({
  order_id,
  product_offer_id,
  product_warehouse_remain_id,
  count,
  price,
}) {
  return axiosIns.post('/shop/orders/product-items', {
    order_id,
    product_offer_id,
    product_warehouse_remain_id,
    count,
    price,
  })
}

export function updateProductItem({
  id,
  price,
  count,
}) {
  return axiosIns.patch(`/shop/orders/product-items/${id}`, {
    price,
    count,
  })
}

export function deleteProductItem(id) {
  return axiosIns.delete(`/shop/orders/product-items/${id}`)
}

export default {
  getProductItem,
  getProductItems,
  createProductItem,
  updateProductItem,
  deleteProductItem,
}
