<template>
  <div class="offer-search-item">
    <div class="row align-items-center no-gutters">
      <div class="col-auto mr-1">
        <div class="offer-search-item__image-container">
          <img
            v-if="image"
            :src="image"
            :alt="name"
          >
          <feather-icon
            v-else
            icon="ImageIcon"
            class="offer-search-item__img-placeholder"
          />
        </div>
      </div>
      <div class="col">
        <div class="offer-search-item__name">
          {{ name }}
        </div>
        <div class="offer-search-item__prices">
          <div class="offer-search-item__prices__now">
            {{ price_now.toLocaleString() }} ₽
          </div>
          <div
            v-if="price_old"
            class="offer-search-item__prices__old"
          >
            {{ price_old.toLocaleString() }} ₽
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="Array.isArray(remains) && remains.length"
      class="offer-search-item__remains"
    >
      <div
        v-for="remain in remains"
        :key="item.id + '_' + remain.id"
        class="offer-search-item__remain"
        @click="$emit('add', remain)"
      >
        <div class="row align-items-center">
          <div class="col-auto">
            <feather-icon icon="PlusIcon" />
          </div>
          <div class="col">
            <div class="offer-search-item__remain__name">
              {{ getWarehouseNameByID(remain.warehouse_id) }}
            </div>

          </div>
          <div class="col-auto">
            <div class="offer-search-item__remain__total">
              Доступно: <span>{{ remain.total - remain.reserve }}</span> шт.
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-alert
      v-else
      variant="primary"
      show
      class="mt-1"
    >
      <div class="alert-body">
        Нет доступных для заказа товаров на складах
      </div>
    </b-alert>
  </div>
</template>

<script>
import get from 'lodash/get'
import { BAlert } from 'bootstrap-vue'
import useWarehouseList from '@/composables/useWarehouseList'

export default {
  name: 'OfferSearchItem',
  setup() {
    const { getWarehouseNameByID } = useWarehouseList()
    return {
      getWarehouseNameByID,
    }
  },
  components: {
    BAlert,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    price_now() {
      const actionPrice = Number(get(this.item, 'sale_price', ''))
      const price = Number(get(this.item, 'price', ''))

      return actionPrice || price
    },
    price_old() {
      const actionPrice = Number(get(this.item, 'sale_price', ''))
      const price = Number(get(this.item, 'price', ''))

      return actionPrice ? price : null
    },
    name() {
      return get(this.item, 'product.name', '')
    },
    image() {
      return get(this.item, 'product.images[0].path', '')
    },
    remains() {
      return get(this.item, 'product_warehouse_remains', []).filter(i => (i.total - i.reserve) > 0)
    },
  },
}
</script>

<style scoped lang="scss">
@import '~@core/scss/base/bootstrap-extended/include'; // Bootstrap includes
@import '~@core/scss/base/components/include'; // Components includes

.offer-search-item{
  &__image-container{
    display: block;
    height: 0;
    width: 60px;
    padding-bottom: 60px;
    position: relative;
    background: #e2e2e2;
    border-radius: 6px;
    overflow: hidden;
    img{
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }

  &__img-placeholder{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    color: #6E6B7B;
    width: 40%;
    height: 40%;
  }

  &__name{
    color: $headings-color;

    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
  }

  &__count{
    display: flex;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
    div:nth-child(1){
      color: $secondary;
      margin-right: 5px;
    }
    div:nth-child(2),div:nth-child(3){
      font-weight: 400;
    }
  }

  &__prices{
    &__now{
      color: $headings-color;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
    }
    &__old{
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 15px;
      text-decoration-line: line-through;

      color: $gray-100;
    }
  }

  &__remains {
  }

  &__remain {
    padding: 0.75rem 1rem;
    background: $gray-200;
    margin-top: 10px;
    border-radius: 6px;
    border: 1px solid $gray-200;
    cursor: pointer;

    &:hover{
      border: 1px solid $primary;
      svg{
        color: $primary;
      }
    }

    &__name {
      font-size: 13px;
      font-weight: bold;
    }

    &__total {
      font-size: 13px;
    }
  }
}
</style>
