<template>
  <div class="navbar-container a-footer">
    <b-row
      no-gutters
      class="w-100"
      align-v="center"
    >
      <b-col
        cols="auto"
        md=""
      >
        <div class="d-flex align-items-center">
          <!-- Nav Menu Toggler -->
          <ul class="nav navbar-nav">
            <li class="nav-item">
              <b-link
                class="nav-link"
                @click="toggleVerticalMenuActive"
              >
                <feather-icon
                  icon="MenuIcon"
                  size="21"
                />
              </b-link>
            </li>
            <search-btn />
          </ul>

          <!-- Left Col -->
          <!-- <div class="bookmark-wrapper align-items-center d-none d-lg-flex">
            <dark-Toggler class="d-none d-lg-block" />
          </div>-->
        </div>
      </b-col>
      <b-col

        cols="auto"
        class="ml-1 ml-md-0"
      >
        <b-button
          variant="primary"
          class="btn-icon rounded-circle a-btn-assistant"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M14.7083 1.40352C13.1481 0.442134 11.342 -0.0443753 9.51031 0.00318129C8.56979 -0.0133865 7.63383 0.137718 6.74626 0.449423C5.14139 0.948974 3.67909 1.82502 2.48123 3.00459L2.47539 3.01034L2.46974 3.01628C1.63208 3.89705 0.982183 4.939 0.559553 6.07882C0.138193 7.21521 -0.0484974 8.42523 0.0107124 9.63575C0.0470793 14.6175 2.84007 17.0715 5.14844 18.2542L5.14843 18.2542L5.15347 18.2568C6.40834 18.8819 7.79096 19.2077 9.19284 19.2084H9.19385L18.7382 19.24L19.2399 19.2417V18.74V9.46242C19.2475 8.06055 18.9253 6.67657 18.2994 5.42231C17.5179 3.76234 16.2701 2.36587 14.7083 1.40352ZM9.53083 2.1294C10.959 2.07464 12.3714 2.44455 13.5896 3.19244C14.8078 3.94032 15.7771 5.03264 16.3751 6.33142L16.3786 6.33885L16.3822 6.34617C16.8603 7.30108 17.104 8.3563 17.0929 9.42427H17.0928V9.42947V17.0821L9.1998 17.0518L9.19838 17.0518C8.12653 17.0408 7.07017 16.7945 6.10393 16.3302C4.86175 15.7246 3.82325 14.7694 3.11591 13.5817C2.40833 12.3937 2.06294 11.0248 2.12215 9.64306L2.12261 9.63236V9.62165C2.12261 7.81307 2.56935 6.34145 3.43268 5.17094C4.2968 3.99936 5.60934 3.08744 7.41365 2.45411C8.0897 2.23388 8.79688 2.12435 9.50786 2.12976L9.51935 2.12984L9.53083 2.1294Z"
              fill="white"
            />
          </svg>
        </b-button>
      </b-col>
      <b-col>

        <div class="d-flex align-items-center justify-content-end">

          <b-navbar-nav class="nav align-items-center flex-nowrap">
            <btn-basket />

            <mode-switch-v2 style="margin-left: 0.5rem;" />
            <b-nav-item-dropdown
              right
              toggle-class="d-flex align-items-center dropdown-user-link"
              class="dropdown-user"
              dropup
            >
              <template #button-content>
                <b-avatar
                  size="40"
                  variant="light-primary"
                  badge
                  class="badge-minimal"
                  badge-variant="success"
                />
              </template>

              <b-dropdown-item link-class="d-flex align-items-center">
                <feather-icon
                  size="16"
                  icon="UserIcon"
                  class="mr-50"
                />
                <span>Профиль</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="$store.getters['workingMode/selected_company_id']"
                link-class="d-flex align-items-center"
                :to="{ name: 'company.show' }"
              >
                <feather-icon
                  size="16"
                  icon="HexagonIcon"
                  class="mr-50"
                />
                <span>
                  Компания
                </span>
              </b-dropdown-item>

              <b-dropdown-divider />

              <b-dropdown-item
                link-class="d-flex align-items-center"
                @click="logout"
              >
                <feather-icon
                  size="16"
                  icon="LogOutIcon"
                  class="mr-50"
                />
                <span>Выйти</span>
              </b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar, BButton, BRow, BCol, BNavItem,
} from 'bootstrap-vue'
import ModeSwitchV2 from '@/layouts/components/ModeSwitchV2.vue'
import BtnBasket from '@/layouts/components/BtnBasket.vue'
import SearchBtn from './SearchBtn.vue'

export default {
  components: {
    BtnBasket,
    BLink,
    BNavbarNav,
    BNavItem,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BButton,
    BRow,
    BCol,

    // Navbar Components
    ModeSwitchV2,

    SearchBtn,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    async logout() {
      await this.$store.dispatch('auth/logout')
    },
  },
}
</script>

<style lang="scss">
.a-footer{
  display: flex;
}

.a-btn-assistant{
  width: 38px;
  height: 38px;
  svg{
    transform: translateX(-2px) translateY(-2px);
  }
}
</style>
