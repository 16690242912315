<template>
  <b-nav-item-dropdown
    ref="mode-switch-v2"
    class="mode-switch-v2 dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
    dropup
  >
    <template #button-content>
      <div class="mode-switch-v2__selected-item">
        <div>
          <template v-if="mode">
            <div class="mode-switch-v2__selected-item__title">
              <template v-if="mode.project.name === 'Default'">
                {{ mode.company.name }}
              </template>
              <template v-else>
                {{ mode.project.name }}
              </template>
            </div>
            <div class="mode-switch-v2__selected-item__desc">
              {{ mode.role.name }}
            </div>
          </template>
          <template v-else>
            <div
              id="mode-switch-v2__select"
              class="mode-switch-v2__selected-item__desc"
            >
              Выберите рабочее окружение
            </div>
            <b-tooltip
              target="mode-switch-v2__select"
              variant="primary"
              :show.sync="showTooltip"
            >
              Выберите рабочее окружение
            </b-tooltip>
          </template>
        </div>
        <div class="mode-switch-v2__selected-item__icon">
          <feather-icon icon="ChevronDownIcon" />
        </div>
      </div>
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Рабочее окружение
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          {{ countOptions }}
        </b-badge>
      </div>
    </li>

    <!-- Items -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <b-link
        v-for="option in options"
        :key="option.project.id"
        class="mode-switch-v2__item"
        :active="option.project.id === $store.getters['workingMode/selected_project_id']"
        @click="setOption(option)"
      >
        <b-media>
          <template #aside>
            <b-avatar
              size="32"
              variant="light-primary"
            >
              <feather-icon icon="BoxIcon" />
            </b-avatar>
          </template>
          <p class="media-heading">
            <template v-if="option.project.name === 'Default'">
              <span class="font-weight-bolder">{{ option.company.name }}</span>
            </template>
            <template v-else>
              <span class="font-weight-bolder">
                {{ option.project.name }}
              </span>
              <small>{{ option.company.name }}</small>
            </template>
          </p>
          <small class="notification-text">{{ option.role.name }}</small>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer"><b-button
      v-b-modal.modal-add-company
      variant="primary"
      block
    >Создать</b-button>
    </li>
    <add-company-modal @ok="runAfterCreate" />
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BTooltip,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import get from 'lodash/get'
import AddCompanyModal from '@/modules/company/components/AddCompanyModal.vue'

export default {
  components: {
    AddCompanyModal,
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BTooltip,
  },
  data() {
    return {
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
      loading: false,
      showTooltip: true,
    }
  },
  computed: {
    options() {
      return this.$store.getters['workingMode/options']
    },
    countOptions() {
      return this.options.length
    },
    mode() {
      return this.$store.getters['workingMode/mode']
    },
    companies() {
      return this.$store.getters['workingMode/companies']
    },
    projects() {
      return this.$store.getters['workingMode/']
    },
  },
  watch: {
    mode(newValue) {
      if (!newValue) {
        this.showTooltip = true
      }
    },
  },
  async mounted() {
    this.loading = true
    await this.$store.dispatch('workingMode/getData')
    this.loading = false
  },
  methods: {
    setOption(option) {
      if (option.project.id === this.$store.getters['workingMode/selected_project_id']) {
        this.$refs['mode-switch-v2'].hide()
        return
      }

      this.$store.commit('workingMode/SET_MODE', option)
      if (this.$route.name !== 'dashboard') {
        this.$router.push({
          name: 'dashboard',
        })
      }
      // todo-gav Когда появится dashboard обновить данные если не было редиректа
      this.$refs['mode-switch-v2'].hide()
    },
    get,
    async runAfterCreate({ project }) {
      this.loading = true
      await this.$store.dispatch('workingMode/getData')
      const option = this.options.find(o => o.project.id === project.id)
      if (option) {
        this.setOption(option)
      }
      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.mode-switch-v2 {
  &__selected-item {
    display: flex;
    align-content: center;
    align-items: center;
    &__title {
      font-weight: bold;
      max-width: 120px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__desc {
      font-size: smaller;
      max-width: 120px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__icon {
      margin-left: 5px;
      transition: transform 0.2s ease;
    }
  }
  &.show{
    .mode-switch-v2__selected-item__icon {
      transform: rotate(180deg);
    }
  }

  &__item.active{
  }
}
</style>
