<template>
  <b-nav-item class="b-basket">
    <div @click="changeSidebar(true)">
      <feather-icon
        :badge="$store.getters['basket/count']"
        class="text-body"
        icon="ShoppingCartIcon"
        size="21"
      />
    </div>

    <b-sidebar
      :visible="isActiveSidebar"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @hidden="runAfterHidingSidebar"
      @change="changeSidebar"
    >
      <template #default="{ hide }">
        <b-overlay
          :show="loading"
          spinner-variant="primary"
        >
          <div class="b-basket__sidebar">
            <div class="b-basket__sidebar__header">
              <div
                class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
              >
                <h5 class="mb-0">
                  Корзина
                </h5>
                <feather-icon
                  class="ml-1 cursor-pointer"
                  icon="XIcon"
                  size="16"
                  @click="hide"
                />
              </div>
            </div>
            <VuePerfectScrollbar class="b-basket__sidebar__content">
              <template v-if="!isEmptyBasket && isActiveSidebar">
                <basket-item
                  v-for="(basketItem, index) in basketItems"
                  :key="'bi' + basketItem.remain_id"
                  :item="basketItem"
                />
              </template>
              <div
                v-else
                class="px-2 py-1"
              >

                <b-alert
                  show
                  variant="primary"
                >
                  <div class="alert-body">
                    Корзина пуста!
                  </div>
                </b-alert>
              </div>
            </VuePerfectScrollbar>
            <div
              v-if="!isEmptyBasket"
              class="b-basket__sidebar__footer"
            >
              <div class="px-2 py-1">
                <div class="b-basket__sidebar__total">
                  <div class="">
                    Итого:
                  </div>
                  <div class="">
                    {{ total.toLocaleString() }} ₽
                  </div>
                </div>
                <b-button
                  block
                  variant="primary"
                  @click="createOrder"
                >
                  Создать заказ
                </b-button>
              </div>
            </div>
          </div>

        </b-overlay>
      </template>
    </b-sidebar>
    <add-offer-to-basket-modal />
  </b-nav-item>
</template>

<script>
import {
  BNavItem,
  BSidebar,
  BOverlay,
  BBadge,
  BMedia,
  BImg,
  BFormSpinbutton,
  BButton,
  BAlert,
} from 'bootstrap-vue'
import {
  ref, watch, computed, getCurrentInstance,
} from 'vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import get from 'lodash/get'
import BasketItem from '@/components/cards/BasketItem.vue'
import AddOfferToBasketModal from '@/components/modals/AddOfferToBasketModal.vue'
import { getProductWarehouseRemains } from '@/services/main-api/shop/product-warehouse-remains'
import useWarehouseList from '@/composables/useWarehouseList'
import { createOrder as createOrderApi } from '@/services/main-api/shop/orders/orders'
import { createProductItem } from '@/services/main-api/shop/orders/product-items'

export default {
  name: 'BtnBasket',
  components: {
    AddOfferToBasketModal,
    BNavItem,
    BSidebar,
    BOverlay,
    BBadge,
    BMedia,
    BImg,
    BFormSpinbutton,
    BButton,
    BAlert,
    VuePerfectScrollbar,
    BasketItem,
  },
  setup(props, context) {
    const instance = getCurrentInstance().proxy

    // BEGIN Base
    const isActiveSidebar = ref(false)
    const loading = ref(false)
    const runAfterHidingSidebar = () => {
    }
    const changeSidebar = val => {
      isActiveSidebar.value = val
    }
    // END Base

    const items = computed(() => instance.$store.getters['basket/items'])

    const remains = ref([])

    const getRemains = async () => {
      try {
        const res = await getProductWarehouseRemains({
          project: instance.$store.getters['workingMode/selected_project_id'],
          perPage: 1000,
          page: 1,
          includes: 'product_offer;product_offer.product;product_offer.product.images',
          ids: items.value.map(i => i.remainId).join(','),
        })
        remains.value = res.data.data
      } catch (e) {
        remains.value = []
      }
    }

    const basketItems = computed(() => items.value.map(i => {
      const remain = remains.value.find(r => r.id === i.remainId)
      return {
        project_id: i.projectId,
        remain_id: i.remainId,
        offer_id: get(remain, 'product_offer_id'),
        warehouse_id: get(remain, 'warehouse_id'),
        reserve: get(remain, 'reserve'),
        total: get(remain, 'total'),
        price: get(remain, 'product_offer.price'),
        sale_price: get(remain, 'product_offer.sale_price'),
        name: get(remain, 'product_offer.product.name'),
        image: get(remain, 'product_offer.product.images[0].path'),
        active: get(remain, 'product_offer.is_active', false),
        count: i.count ? i.count : 1,
      }
    }))

    const isEmptyBasket = computed(() => !get(basketItems, 'value.length', 0))

    const total = computed(() => get(basketItems, 'value', []).reduce((sum, item) => {
      const actionPrice = Number(get(item, 'sale_price', ''))
      const price = Number(get(item, 'price', ''))
      const nowPrice = actionPrice || price
      return sum + (nowPrice * item.count)
    }, 0))

    const { getItems: getWarehouses } = useWarehouseList()

    const getData = async () => {
      loading.value = true
      await Promise.all([
        getRemains(),
        getWarehouses(),
      ])
      loading.value = false
    }

    watch(isActiveSidebar, newValue => {
      if (newValue) {
        getData()
      }
    })

    const addOrderItem = async orderItem => {
      try {
        await createProductItem({
          order_id: orderItem.order_id,
          product_offer_id: orderItem.product_offer_id,
          product_warehouse_remain_id: orderItem.product_warehouse_remain_id,
          count: orderItem.count,
        })
      } catch (e) {
        // ...
      }
    }

    const createOrder = async () => {
      loading.value = true
      try {
        const res = await createOrderApi({
          project_id: instance.$store.getters['workingMode/selected_project_id'],
          status_id: 2, // в обработке
        })
        const id = get(res, 'data.data.id')

        const orderItems = basketItems.value.map(i2 => ({
          order_id: id,
          product_offer_id: i2.offer_id,
          product_warehouse_remain_id: i2.remain_id,
          count: i2.count,
        }))

        const promises = orderItems.map(addOrderItem)

        await Promise.all(promises)

        isActiveSidebar.value = false
        instance.$store.commit('basket/CLEAR', instance.$store.getters['workingMode/selected_project_id'])

        instance.$router.push({
          name: 'orders.show',
          params: { id },
        })
      } catch (e) {
        // ...
      } finally {
        loading.value = false
      }
    }

    return {
      // Base
      isActiveSidebar,
      loading,
      runAfterHidingSidebar,
      changeSidebar,

      isEmptyBasket,
      basketItems,
      total,

      createOrder,

      get,
    }
  },
}
</script>

<style scoped lang="scss">
@import '~@core/scss/base/bootstrap-extended/include'; // Bootstrap includes
@import '~@core/scss/base/components/include'; // Components includes

.b-basket{
  &__sidebar{
    height: 100vh;
    display: grid;
    grid-template-areas:
      "header"
      "content"
      "footer"
    ;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
    &__header {
      grid-area: header;
      border-bottom: 1px solid $border-color;
    }
    &__content {
      grid-area: content;
    }
    &__footer {
      grid-area: footer;
      border-top: 1px solid $border-color;
    }
    &__total{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 0 10px;
      div:nth-child(1){

      }
      div:nth-child(2){
        font-weight: bold;
        font-size: 18px;
      }
    }
  }

}
</style>
