import axiosIns from '@/libs/axios'

export function getCategories({
  project, perPage, sortedBy, orderBy, page, search, searchFields, searchJoin, includes, mode,
}) {
  return axiosIns.get('/catalog/products/categories', {
    params: {
      project,
      perPage,
      sortedBy,
      orderBy,
      page,
      search,
      searchFields,
      searchJoin,
      includes,
      mode,
    },
  })
}

export function getCategory({ id, includes }) {
  return axiosIns.get(`/catalog/products/categories/${id}`, {
    params: {
      includes,
    },
  })
}

export function deleteCategory(id) {
  return axiosIns.delete(`/catalog/products/categories/${id}`)
}

export function createCategory({
  title,
  slug,
  parent_id,
  project_id,
}) {
  return axiosIns.post('/catalog/products/categories', {
    title,
    slug,
    parent_id,
    project_id,
  })
}

export function updateCategory({
  id,
  title,
  slug,
  parent_id,
}) {
  return axiosIns.patch(`/catalog/products/categories/${id}`, {
    title,
    slug,
    parent_id,
  })
}

export default {
  getCategories,
  getCategory,
  createCategory,
  updateCategory,
  deleteCategory,
}
