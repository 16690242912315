<template>
  <b-modal
    id="modal-add-offer-to-basket"
    v-model="showModalAddOfferToBasket"
    class="add-offer-to-basket-modal"
    centered
    title="Добавить в корзину"
    modal-class="modal-primary"
    ok-title="Отмена"
    ok-only
    ok-variant="secondary"
    @show="showHandler"
    @hidden="hiddenHandler"
  >
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      variant="white"
    >
      <offer-add-item
        v-if="offer"
        :item="offer"
        @add="addOffer"
      />
      <div
        v-else
        class="py-2"
      />
    </b-overlay>
  </b-modal>
</template>

<script>
import { BModal, BOverlay } from 'bootstrap-vue'
import OfferAddItem from '@/components/cards/OfferAddItem.vue'
import useWarehouseList from '@/composables/useWarehouseList'
import { getProductOffer } from '@/services/main-api/shop/product-offers'

export default {
  name: 'AddOfferToBasketModal',
  components: {
    OfferAddItem,
    BModal,
    BOverlay,
  },
  setup() {
    const { getItems: getWarehouses } = useWarehouseList()
    return {
      getWarehouses,
    }
  },
  data() {
    return {
      loading: false,
      offer: null,
    }
  },
  computed: {
    offerID() {
      return this.$store.getters['basket/selectedItemId']
    },
    showModalAddOfferToBasket: {
      get() {
        return this.$store.getters['basket/showModalAddOfferToBasket']
      },
      set(value) {
        this.$store.commit('basket/SET_SHOW_MODAL_ADD_OFFER_TO_BASKET', value)
      },
    },
  },
  watch: {
    async offerID(newValue) {
      if (newValue) {
        this.loading = true
        await Promise.all([
          this.getWarehouses(),
          this.getOffer(),
        ])
        this.loading = false
      }
    },
  },
  methods: {
    showHandler() {

    },
    hiddenHandler() {

    },
    async getOffer() {
      try {
        const res = await getProductOffer({
          id: this.offerID,
          includes: 'product;product.images;product_warehouse_remains',
        })
        this.offer = res.data.data
      } catch (e) {
        this.offer = null
      }
    },
    async addOffer(remain) {
      await this.$store.dispatch('basket/addItem', remain)
    },
  },
}
</script>

<style scoped lang="scss">

</style>
