<template>
  <b-modal
    id="modal-add-company"
    class="add-company-modal"
    centered
    title="Создать компанию"
    modal-class="modal-primary"
    ok-title="Создать"
    cancel-title="Отмена"
    @show="clearForm"
    @hidden="clearForm"
    @ok="handleOk"
  >
    <b-overlay
      :show="loading"
      spinner-variant="primary"
    >
      <small
        v-if="error_message"
        class="text-danger d-block mb-1"
      >
        {{ error_message }}
      </small>
      <b-form-group
        label="Название"
        label-for="name"
      >
        <b-form-input
          id="name"
          v-model.trim="item.name"
          placeholder="Введите название компании"
          :state="get(validation, 'name[0]') ? false : null"
        />
        <small
          v-if="!!get(validation, 'name[0]')"
          class="text-danger"
        >
          {{ get(validation, 'name[0]') }}
        </small>
      </b-form-group>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BOverlay,
  BFormInput,
} from 'bootstrap-vue'
import { get } from 'lodash'
import { getCurrentInstance, ref } from 'vue'
import useCrudCreate from '@/composables/useCrudCreate'
import { createCompany as createItemApi } from '@/services/main-api/business/companies'
import { createProject } from '@/services/main-api/business/projects'

export default {
  name: 'AddCompanyModal',
  components: {
    BModal,
    BFormGroup,
    BOverlay,
    BFormInput,
  },
  setup() {
    const instance = getCurrentInstance().proxy
    const createdData = {}

    const createEntity = async () => {
      try {
        const res = await createItemApi({
          name: instance.item.name,
        })
        createdData.company = res.data.data
        const resp = await createProject({
          name: 'Default',
          company_id: createdData.company.id,
        })
        createdData.project = resp.data.data
      } catch (e) {
        //
      }
    }

    const {
      item,
      loading,
      validation,
      error_message,
      checkValidation,
      clearForm,
      catchValidation,
      createItem,
    } = useCrudCreate({
      item: {
        id: null,
        name: '',
      },
      validation: {
        rules: {
          name: 'required',
        },
        customErrorMessages: {
          'required.name': 'Укажите название',
        },
      },
      createEntity,
      getTextAfterCreation: () => 'Компания была создана.',
      runAfterCreation: async () => {
        instance.$nextTick(() => {
          instance.$bvModal.hide('modal-add-company')
          instance.$emit('ok', createdData)
        })
      },
    })

    const handleOk = async bvModalEvt => {
      bvModalEvt.preventDefault()
      await createItem()
    }

    return {
      item,
      loading,
      validation,
      error_message,
      checkValidation,
      clearForm,
      catchValidation,
      createItem,

      handleOk,
      get,
    }
  },
}
</script>
