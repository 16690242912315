<template>
  <div
    class="order-item"
    :class="{
      'order-item__disable': remain_available <=0 || !active
    }"
  >
    <div class="row no-gutters">
      <div class="col-auto mr-1">
        <div
          class="order-item__image-container"
        >
          <img
            v-if="image"
            :src="image"
            :alt="name"
          >
          <feather-icon
            v-else
            icon="ImageIcon"
            class="order-item__img-placeholder"
          />
        </div>
      </div>
      <div class="col mr-1">
        <div class="order-item__name">
          {{ name }}
        </div>
        <div class="order-item__prices">
          <div class="order-item__prices__now">
            {{ price_now.toLocaleString() }} ₽
          </div>
          <div
            v-if="price_old"
            class="order-item__prices__old"
          >
            {{ price_old.toLocaleString() }} ₽
          </div>
        </div>
        <div class="order-item__count">
          <div class="">
            {{ getWarehouseNameByID(item.warehouse_id) }}:
          </div>
          <div
            v-if="remain_available"
            class="text-success"
          >
            Доступно {{ remain_available }} шт.
          </div>
          <div
            v-else
            class="text-danger"
          >
            Доступно 0 шт.
          </div>
        </div>
        <div
          v-if="!active"
          class="small text-danger"
        >
          Товар выключен!
        </div>
      </div>
      <div
        class="col-auto"
      >
        <b-button
          variant="flat-secondary"
          class="btn-icon"
          size="sm"
          @click="deleteItem"
        >
          <feather-icon icon="XIcon" />
        </b-button>
      </div>
    </div>
    <div class="row no-gutters justify-content-between mt-1 align-items-center">
      <div class="col-auto">
        <b-form-spinbutton
          v-model="count"
          min="1"
          :max="remain_available"
          size="sm"
        />
      </div>
      <div class="col-auto">
        1
      </div>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BFormSpinbutton,
} from 'bootstrap-vue'
import get from 'lodash/get'
import useWarehouseList from '@/composables/useWarehouseList'

export default {
  name: 'BasketItem',
  setup() {
    const { getWarehouseNameByID } = useWarehouseList()
    return {
      getWarehouseNameByID,
    }
  },
  components: {
    BButton,
    BFormSpinbutton,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    editing: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    active() {
      return get(this.item, 'active', '')
    },
    name() {
      return get(this.item, 'name', '')
    },
    image() {
      return get(this.item, 'image', null)
    },
    price_now() {
      const actionPrice = Number(get(this.item, 'sale_price', ''))
      const price = Number(get(this.item, 'price', ''))

      return actionPrice || price
    },
    price_old() {
      const actionPrice = Number(get(this.item, 'sale_price', ''))
      const price = Number(get(this.item, 'price', ''))

      return actionPrice ? price : null
    },
    remain_total() {
      return get(this.item, 'total', 0)
    },
    remain_reserve() {
      return get(this.item, 'reserve', 0)
    },
    remain_available() {
      return this.remain_total - this.remain_reserve
    },
    count: {
      get() {
        return get(this.item, 'count', 1)
      },
      set(newValue) {
        let value = newValue

        if (value > this.remain_available) {
          value = this.remain_available
        }

        if (value < 1) {
          value = 1
        }

        this.$store.commit('basket/SET_COUNT_ITEM', {
          projectId: this.item.project_id,
          remainId: this.item.remain_id,
          count: value,
        })
      },
    },
  },
  methods: {
    // setCount(value) {
    //   // this.$emit('setCount', {
    //   //   item: this.item,
    //   //   count: value,
    //   // })
    // },
    deleteItem() {
      this.$store.commit('basket/DELETE_ITEM', {
        projectId: this.item.project_id,
        remainId: this.item.remain_id,
      })
    },
  },
}
</script>

<style scoped lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
// Overrides user variable
@import '~@core/scss/base/components/include';
.order-item{
  padding: 15px 20px 15px;
  border-bottom: 1px solid $border-color;

  &__disable{
    background: lighten($red, 35);
  }

  &:last-child{
    border-bottom: 0;
  }

  &__image-container{
    display: block;
    height: 0;
    width: 60px;
    padding-bottom: 60px;
    position: relative;
    background: #e2e2e2;
    border-radius: 6px;
    overflow: hidden;
    img{
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }

  &__img-placeholder{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    color: #6E6B7B;
    width: 40%;
    height: 40%;
  }
  &__name{
    color: $headings-color;

    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
  }
  &__prices{
    &__now{
      color: $headings-color;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
    }
    &__old{
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 15px;
      text-decoration-line: line-through;

      color: $gray-100;
    }
  }

  &__total{
    text-align: right;
    color: $headings-color;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    margin-top: 10px;
  }

  &__count{
    display: flex;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
    div:nth-child(1){
      color: $secondary;
      margin-right: 5px;
    }
    div:nth-child(2),div:nth-child(3){
      font-weight: 400;
    }
  }
}
</style>
