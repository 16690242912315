<template>
  <ul class="nav navbar-nav flex-row">
    <!-- Logo & Text -->
    <li class="nav-item mr-auto">
      <b-link
        class="navbar-brand"
        to="/"
      >
        <span class="brand-logo">
          <logo-app type="pic" />
        </span>
        <div class="brand-text">
          <logo-app type="brand" />
        </div>
      </b-link>
    </li>

    <!-- Toggler Button -->
    <!--    <li class="nav-item nav-toggle">-->
    <!--      <b-link class="nav-link modern-nav-toggle">-->
    <!--        <feather-icon-->
    <!--          icon="XIcon"-->
    <!--          size="20"-->
    <!--          class="d-block d-xl-none"-->
    <!--          @click="toggleVerticalMenuActive"-->
    <!--        />-->
    <!--        <feather-icon-->
    <!--          :icon="collapseTogglerIconFeather"-->
    <!--          size="20"-->
    <!--          class="d-none d-xl-block collapse-toggle-icon"-->
    <!--          @click="toggleCollapsed"-->
    <!--        />-->
    <!--      </b-link>-->
    <!--    </li>-->
  </ul>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import { computed } from 'vue'
import { $themeConfig } from '@themeConfig'
import useVerticalNavMenu from '@core/layouts/layout-vertical/components/vertical-nav-menu/useVerticalNavMenu'
import LogoApp from '@/components/LogoApp.vue'

export default {
  components: {
    BLink,
    LogoApp,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const {
      isVerticalMenuCollapsed,
      toggleCollapsed,
    } = useVerticalNavMenu(props)
    const collapseTogglerIconFeather = computed(() => (isVerticalMenuCollapsed.value ? 'CircleIcon' : 'DiscIcon'))

    // App Name
    const {
      appName,
      appLogoImage,
    } = $themeConfig.app

    return {
      isVerticalMenuCollapsed,
      toggleCollapsed,
      collapseTogglerIconFeather,

      // App Name
      appName,
      appLogoImage,
    }
  },
}
</script>

<style lang="scss" scoped>
.navbar-brand{
  height: 28px !important;
}
</style>
