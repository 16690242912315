<script setup>
import {
  BModal, BFormInput, BInputGroup, BInputGroupPrepend, BLink,
} from 'bootstrap-vue'
import debounce from 'lodash/debounce'
import store from '@/store'

import { ref, watch } from 'vue'
import { getCategories as getCategoriesApi } from '@/services/main-api/catalog/products/categories'
import { getProductOffersByProducts } from '@/services/main-api/shop/product-offers'

const globalSearchModal = ref(null)

const searchQuery = ref('')
const loading = ref(false)

function showHandler() {
  searchQuery.value = ''
}
function hiddenHandler() {
  searchQuery.value = ''
}

// BEGIN Categories
const categories = ref([])
const getCategories = async () => {
  let search = null

  const searchFields = []

  if (searchQuery.value) {
    searchFields.push(`name:${searchQuery.value}`)
  } else {
    categories.value = []
    return
  }

  if (searchFields.length) {
    search = searchFields.join(';')
  }

  try {
    const res = await getCategoriesApi({
      project: store.getters['workingMode/selected_project_id'],
      search,
      searchJoin: 'and',
      perPage: 15,
      sortedBy: 'asc',
      orderBy: 'name',
      page: 1,
      mode: 'by_product_offers',
    })
    categories.value = res.data.data
  } catch (e) {
    categories.value = []
  }
}
// END Categories

// BEGIN Offers
const offers = ref([])

const getOffers = async () => {
  let relationSearch = null

  const relationSearchFields = []

  if (searchQuery.value) {
    relationSearchFields.push(`name:${searchQuery.value}`)
  } else {
    offers.value = []
  }

  if (relationSearchFields.length) {
    relationSearch = relationSearchFields.join(';')
  }

  try {
    const res = await getProductOffersByProducts({
      project: store.getters['workingMode/selected_project_id'],
      searchJoin: 'and',
      relationSearchJoin: 'and',
      perPage: 50,
      sortedBy: 'asc',
      orderBy: 'name',
      page: 1,
      mode: 'by_offer',
      relationSearch,
    })
    offers.value = res.data.data
  } catch (e) {
    offers.value = []
  }
}
// END Offers

const getData = async () => {
  await Promise.all([
    getCategories(),
    getOffers(),
  ])
}

const debouncedSearch = debounce(async () => {
  loading.value = true
  await getData()
  loading.value = false
}, 500)

watch(searchQuery, () => {
  debouncedSearch()
})
</script>

<template>
  <b-modal
    id="modal-global-search"
    ref="globalSearchModal"
    scrollable
    title="Поиск"
    modal-class="modal-primary modal-global-search"
    hide-footer
    size="lg"
    @show="showHandler"
    @hidden="hiddenHandler"
  >
    <div class="modal-global-search__container">
      <b-input-group
        class="input-group-merge modal-global-search__input"
      >
        <b-input-group-prepend is-text>
          <feather-icon icon="SearchIcon" />
        </b-input-group-prepend>

        <b-form-input
          v-model.trim="searchQuery"
          placeholder="Поиск..."
          autofocus
          autocomplete="off"
        />
      </b-input-group>
      <div
        v-if="loading"
        class="loader"
      />
      <ul
        v-if="searchQuery"
        class="search-list search-list-main scroll-area overflow-hidden"
      >
        <li
          class="suggestions-groups-list"
        >
          <!-- Group Header -->
          <p class="suggestion-group-title">
            <span>
              Категории
            </span>
          </p>
          <!-- Suggestion List of each group -->

          <ul>
            <template v-if="categories.length">
              <li
                v-for="category in categories"
                :key="'gsc' + category.id"
                class="suggestion-group-suggestion cursor-pointer"
              >
                <b-link
                  class="p-0 text-secondary"
                  :to="`/catalog/${category.id}`"
                >
                  <feather-icon
                    icon="FolderIcon"
                    class="mr-75"
                  />
                  <span class="align-middle">{{ category.name }}</span>
                </b-link>
              </li>
            </template>

            <li
              v-else
              class="suggestion-group-suggestion no-results"
            >
              <p>Не найдены</p>
            </li>
          </ul>
        </li>

        <li
          class="suggestions-groups-list"
        >
          <!-- Group Header -->
          <p class="suggestion-group-title">
            <span>
              Предложения
            </span>
          </p>
          <!-- Suggestion List of each group -->

          <ul>
            <template v-if="offers.length">
              <li
                v-for="offer in offers"
                :key="'gso' + offer.id"
                class="suggestion-group-suggestion cursor-pointer"
              >
                <b-link
                  class="p-0 text-secondary"
                  :to="`/offers/show/${offer.id}`"
                >
                  <feather-icon
                    icon="BoxIcon"
                    class="mr-75"
                  />
                  <span class="align-middle">{{ offer.product.name }}</span>
                </b-link>
              </li>
            </template>
            <li
              v-else
              class="suggestion-group-suggestion no-results"
            >
              <p>Не найдены</p>
            </li>
          </ul>
        </li>

      </ul>
    </div>
  </b-modal>
</template>

<style scoped lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.modal-global-search{
  position: relative;
  &__input{
    position: sticky;
    top: 0;
    left: 0;
    right: 0;

    background-color: #fff;
    z-index: 1;
    box-shadow: 0 0 5px 0 #fff;

    .dark-layout & {
      background-color: $theme-dark-body-bg;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  p {
    margin: 0;
  }
  .search-list-main{
    margin-top: .5rem;
  }

  .suggestion-group-title {
    font-weight: 500;
    padding: .75rem 1rem .25rem;
  }

  .suggestion-group-suggestion {
    padding: .75rem 1rem;
    &:hover:not(.no-results){
      background-color: $body-bg;
      border-radius: 6px;
      .dark-layout & {
        background-color: $theme-dark-body-bg;
      }
    }
  }

  .suggestion-current-selected {
    background-color: $body-bg;

    .dark-layout & {
      background-color: $theme-dark-body-bg;
    }
  }

  .no-results{

  }

  .loader {
    width: 100%;
    height: 4px;
    border-radius: 4px;
    display: inline-block;
    position: relative;
    background: lighten($primary, 40);
    overflow: hidden;
  }
  .loader::after {
    content: '';
    width: 192px;
    height: 4.8px;
    background: $primary;
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    animation: animloader 2s linear infinite;
  }

  @keyframes animloader {
    0% {
      left: 0;
      transform: translateX(-100%);
    }
    100% {
      left: 100%;
      transform: translateX(0%);
    }
  }

}
</style>
