import axiosIns from '@/libs/axios'

export function getOrder({ id, includes }) {
  return axiosIns.get(`/shop/orders/orders/${id}`, {
    params: {
      includes,
    },
  })
}

export function getOrders({
  project, perPage, sortedBy, orderBy, page, search, searchFields, searchJoin, includes,
}) {
  return axiosIns.get('/shop/orders/orders', {
    params: {
      project,
      perPage,
      sortedBy,
      orderBy,
      page,
      search,
      searchFields,
      searchJoin,
      includes,
    },
  })
}

export function deleteOrder(id) {
  return axiosIns.delete(`/shop/orders/orders/${id}`)
}

export function createOrder({
  project_id, status_id, person_id, notes,
}) {
  return axiosIns.post('/shop/orders/orders', {
    project_id, status_id, person_id, notes,
  })
}

export function updateOrder({
  id, status_id, person_id, notes, step_id, external_shipment_boxes,
}) {
  return axiosIns.patch(`/shop/orders/orders/${id}`, {
    status_id, person_id, notes, step_id, external_shipment_boxes,
  })
}

export function getLabels({ id }) {
  return axiosIns.get(`/shop/orders/orders/${id}/labels`, {
    responseType: 'blob',
  })
}

export function getShipment({ id }) {
  return axiosIns.get(`/shop/orders/orders/${id}/shipment`)
}

export function getExternalStats({ id }) {
  return axiosIns.get(`/shop/orders/orders/${id}/external-stats`)
}

export function getExternalData({ id }) {
  return axiosIns.get(`/shop/orders/orders/${id}/external-data`)
}

export default {
  getOrders,
  getOrder,
  deleteOrder,
  createOrder,
  updateOrder,
  getLabels,
  getShipment,
  getExternalStats,
  getExternalData,
}
