import axiosIns from '@/libs/axios'

export function getProductOffers({
  project, perPage, sortedBy, orderBy, page, search, searchFields, searchJoin, includes,
}) {
  return axiosIns.get('/shop/product-offers', {
    params: {
      project,
      perPage,
      sortedBy,
      orderBy,
      page,
      search,
      searchFields,
      searchJoin,
      includes,
    },
  })
}

export function getProductOffersByProducts({
  project,
  perPage,
  sortedBy,
  orderBy,
  page,
  search,
  searchFields,
  searchJoin,
  relationSearch,
  relationSearchFields,
  relationSearchJoin,
  includes,
}) {
  return axiosIns.get('/shop/product-offers/by-products', {
    params: {
      project,
      perPage,
      sortedBy,
      orderBy,
      page,
      search,
      searchFields,
      searchJoin,
      relationSearch,
      relationSearchFields,
      relationSearchJoin,
      includes,
    },
  })
}

export function getProductOffer({ id, includes }) {
  return axiosIns.get(`/shop/product-offers/${id}`, {
    params: {
      includes,
    },
  })
}

export function deleteProductOffer(id) {
  return axiosIns.delete(`/shop/product-offers/${id}`)
}

export function createProductOffer({
  project_id, product_id, price, sale_price, purchase_price, is_active, description, yandex_market_fbs, yandex_market_dbs, merlion_fresh_ymfbs, merlion_ymdbs, external_public_id, website_content,
}) {
  return axiosIns.post('/shop/product-offers', {
    project_id,
    product_id,
    price: price || null,
    sale_price: sale_price || null,
    purchase_price: purchase_price || null,
    is_active,
    description,
    yandex_market_fbs,
    yandex_market_dbs,
    merlion_fresh_ymfbs,
    merlion_ymdbs,
    external_public_id,
    website_content,
  })
}

export function updateProductOffer({
  id, price, sale_price, purchase_price, is_active, description, yandex_market_fbs, yandex_market_dbs, merlion_fresh_ymfbs, merlion_ymdbs, external_public_id, website_content,
}) {
  return axiosIns.patch(`/shop/product-offers/${id}`, {
    price: price === '' ? null : price,
    sale_price: sale_price === '' ? null : sale_price,
    purchase_price: purchase_price === '' ? null : purchase_price,
    is_active,
    description,
    yandex_market_fbs,
    yandex_market_dbs,
    merlion_fresh_ymfbs,
    merlion_ymdbs,
    external_public_id,
    website_content,
  })
}

export function attachProductContentToProductOffer({ id, contentId, sortOrder }) {
  return axiosIns.post(`/shop/product-offers/${id}/product-content/${contentId}`, {
    sort_order: sortOrder,
  })
}

export function updateAttachingProductContentToProductOffer({ id, contentId, sortOrder }) {
  return axiosIns.patch(`/shop/product-offers/${id}/product-content/${contentId}`, {
    sort_order: sortOrder,
  })
}

export function deleteAttachingProductContentToProductOffer({ id, contentId }) {
  return axiosIns.delete(`/shop/product-offers/${id}/product-content/${contentId}`)
}

export default {
  getProductOffers,
  getProductOffer,
  deleteProductOffer,
  createProductOffer,
  updateProductOffer,
  attachProductContentToProductOffer,
  updateAttachingProductContentToProductOffer,
  deleteAttachingProductContentToProductOffer,
  getProductOffersByProducts,
}
