import axiosIns from '@/libs/axios'

export function getProductWarehouseRemain({ id, includes }) {
  return axiosIns.get(`/shop/product-warehouse-remains/${id}`, {
    params: {
      includes,
    },
  })
}

export function getProductWarehouseRemains({
  project, perPage, sortedBy, orderBy, page, search, searchFields, searchJoin, includes, ids,
}) {
  return axiosIns.get('/shop/product-warehouse-remains', {
    params: {
      project,
      perPage,
      sortedBy,
      orderBy,
      page,
      search,
      searchFields,
      searchJoin,
      includes,
      ids,
    },
  })
}

export function createProductWarehouseRemain({
  warehouse_id,
  product_offer_id,
  total,
}) {
  return axiosIns.post('/shop/product-warehouse-remains', {
    warehouse_id,
    product_offer_id,
    total,
  })
}

export function updateProductWarehouseRemain({
  id,
  total,
}) {
  return axiosIns.patch(`/shop/product-warehouse-remains/${id}`, {
    total,
  })
}

export function deleteProductWarehouseRemain(id) {
  return axiosIns.delete(`/shop/product-warehouse-remains/${id}`)
}

export default {
  getProductWarehouseRemain,
  getProductWarehouseRemains,
  createProductWarehouseRemain,
  updateProductWarehouseRemain,
  deleteProductWarehouseRemain,
}
