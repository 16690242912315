<template>
  <layout-vertical>
    <router-view />

    <template #vertical-menu-header="slot_props">
      <vertical-menu-header v-bind="slot_props" />
    </template>

    <template #navbar="{ toggleVerticalMenuActive }">
      <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template>

    <template #breadcrumb>
      <app-breadcrumb />
    </template>
  </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
import AppBreadcrumb from '../components/AppBreadcrumb.vue'
import Navbar from '../components/Navbar.vue'
import VerticalMenuHeader from '../components/VerticalMenuHeader.vue'

export default {
  components: {
    VerticalMenuHeader,
    AppBreadcrumb,
    // AppCustomizer,
    LayoutVertical,
    Navbar,
  },
  data() {
    return {
    }
  },
}
</script>
